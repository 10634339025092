/**
 * This file is part of VILLASweb.
 *
 * VILLASweb is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * VILLASweb is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with VILLASweb. If not, see <http://www.gnu.org/licenses/>.
 ******************************************************************************/

const villasweb_values = {
   title: 'VILLASweb',
   subtitle: 'ACS',
   logo: 'villas_web.svg',
   pages: {
      home: true,
      scenarios: true,
      infrastructure: true,
      account: true,
      api: true,
   },
   style: {
      background: '#6EA2B0',
      highlights: '#527984',
      maincolor: '#4d4d4d',
      secondarytext: '#818181',
   }
}

export default villasweb_values;